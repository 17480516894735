<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Tipos de resultado</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Tipos de
                  <span>resultado</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row mt-4">
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibir"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Lista de resultados
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            <button
                              class="btn btn-sm btn-primary"
                              @click.prevent="alteraFastNavegacao('criar')"
                            >
                              <small>+ Cadastrar tipo de resultado</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Filtro por nome da sala"
                              @keyup="filtroNomeResultado"
                            >
                          </div>                          
                        </div>
                        <div class="row">
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr class="text-center">
                                  <th class="text-center">
                                    <small class="font-weight-bold">Tipo de resultado</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Aprovação</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Reprovação</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Requisitos</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastResultados.loading">
                                <tr>
                                  <td
                                    colspan="5"
                                    class="text-center"
                                    v-html="fastLoadingDados()"
                                  />
                                </tr>
                              </tbody>
                              <tbody v-else-if="fastResultados.paginacao.length">
                                <tr
                                  v-for="(e, index) in fastResultados.paginacao"
                                  :key="index"
                                >
                                  <td class="align-middle text-center">
                                    <small>{{ e.nome_tipo_resultado }}</small>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.indica_aprovacao"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.indica_reprovacao"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="align-middle text-center">
                                    <span
                                      v-if="e.libera_preriquisito"
                                      class="btn btn-sm btn-success pt-0 pb-0"
                                    >
                                      <small>sim</small>
                                    </span>
                                    <span
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0"
                                    >
                                      <small>não</small>
                                    </span>
                                  </td>
                                  <td class="text-center align-middle">                                  
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="exibeModalEditarFastResultado(e)"
                                    >
                                      <small>Editar</small>
                                    </button>
                                    <button
                                      class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                      @click="exibeModalExcluirFastResultado(e)"
                                    >
                                      <small>Excluir</small>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="5"
                                    class="text-center"
                                  >
                                    Nenhum resultado cadastrado
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-12 text-center mt-4">
                            <Pagination
                              :page-size="10"
                              :items="fastResultados.filtro"
                              @changePage="
                                fastResultados.paginacao = $event
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.criar"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Nova sala
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome do tipo de resultado <small class="text-danger">*</small></label>
                              <input
                                v-model="fastResultadoAlunoNovo.nome_tipo_resultado"
                                type="text"
                                :class="!fastResultadoAlunoNovo.nome_tipo_resultado ? 'form-control border border-danger' : 'form-control'"
                                maxlength="50"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Indica aprovação</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastResultadoAlunoNovo.indica_aprovacao"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Indica reprovação</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastResultadoAlunoNovo.indica_reprovacao"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Libera pré-requisito</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastResultadoAlunoNovo.libera_preriquisito"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Plataformas filhas</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastResultadoAlunoNovo.deriva_plataforma_filha"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 mt-4">
                              <div class="form-group mb-0">
                                <button
                                  v-if="!fastBtnLoading"
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                  @click="criarFastResultado()"
                                >
                                  Cadastrar resultado
                                </button>
                                <button
                                  v-else
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                >
                                  <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Aguarde...
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-secondary mr-1"
                                  @click="getfastResultados($route.params.id_plataforma)"
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.editar"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Editar resultado
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-4">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome do tipo de resultado <small class="text-danger">*</small></label>
                              <input
                                v-model="fastResultadoAlunoEditar.nome_tipo_resultado"
                                type="text"
                                :class="!fastResultadoAlunoEditar.nome_tipo_resultado ? 'form-control border border-danger' : 'form-control'"
                                maxlength="50"
                              >
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Indica aprovação</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastResultadoAlunoEditar.indica_aprovacao"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Indica reprovação</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastResultadoAlunoEditar.indica_reprovacao"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Libera pré-requisito</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastResultadoAlunoEditar.libera_preriquisito"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                              <label>Plataformas filhas</label>
                              <div class="fd-app-meus-cursos mt-0 ml-1">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                                    <label class="switch">
                                      <input
                                        v-model="fastResultadoAlunoEditar.deriva_plataforma_filha"
                                        checked="checked"
                                        type="checkbox"
                                      >
                                      <span class="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 mt-4">
                              <div class="form-group mb-0">
                                <button
                                  v-if="!fastBtnLoading"
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                  @click="editarFastResultado()"
                                >
                                  Salvar alterações
                                </button>
                                <button
                                  v-else
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-primary mr-1"
                                >
                                  <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Aguarde...
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-sm btn-secondary mr-1"
                                  @click="getfastResultados($route.params.id_plataforma)"
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">               
                <a
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalExcluirResultado"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Excluir resultado?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirResultado')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6>O tipo de resultado <span class="text-danger">{{ fastResultadoAlunoEditar.nome_tipo_resultado }} </span> será excluído e essa operação não poderá ser revertida.</h6>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  v-if="!fastBtnLoading"
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirFastResultado()"
                >
                  Confirmar
                </button>
                <button
                  v-else
                  href="#"
                  type="button"
                  class="btn btn-danger"
                >
                  <b-icon
                    icon="gear-fill"
                    animation="spin"
                  /> Aguarde...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaResultadoAluno",
  components: {Pagination},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "100%" : "30%",
      modalWidthMd: this.isMobile() ? "100%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastBtnLoading: false,
      fastNavegacao: {
        exibir: true,
        criar: false,
        alterar: false,
      },
      fastResultados: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      fastResultadoAlunoNovo: {
        id_tipo_resultado_aluno: 0,
        nome_tipo_resultado: "",
        indica_aprovacao: false,
        indica_reprovacao: false,
        libera_preriquisito: false,
        id_plataforma: this.$route.params.id_plataforma,
        deriva_plataforma_filha: false,        
      },
      fastResultadoAlunoEditar: {
        id_tipo_resultado_aluno: "",
        nome_tipo_resultado: "",
        indica_aprovacao: "",
        indica_reprovacao: "",
        libera_preriquisito: "",
        id_plataforma: 0,
        deriva_plataforma_filha: "",        
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getfastResultados(this.$route.params.id_plataforma);
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    async getfastResultados(id_plataforma){
      this.alteraFastNavegacao('exibir')   
      this.fastResultados.loading = true
      this.promiseGetFastApi("api/fast_secretaria_resultado_aluno/lista", "id_plataforma=" + id_plataforma).then(obj => {
        if (obj.length) {
          this.fastResultados.total = obj
          this.fastResultados.filtro = obj
        }
        else {
          this.fastResultados.total = []
          this.fastResultados.filtro = []
        }            
        this.fastResultados.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar resultados", "error");
        this.fastResultados.loading = false
      })
    },
    filtroNomeResultado(e) {
      let text = e.target.value;
      this.fastResultados.filtro = this.fastResultados.total.filter(
        (e) => {
          return (
            e.nome_tipo_resultado.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
          );
        }
      );
    },
    async criarFastResultado(){
      this.fastBtnLoading = true   
      let erros = []
      if (!this.fastResultadoAlunoNovo.nome_tipo_resultado) erros.push("O nome do tipo de resultado é obrigatório")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastBtnLoading = false
      } else {        
          this.promisePostFastApi(this.fastResultadoAlunoNovo, "api/fast_secretaria_resultado_aluno/insere").then((res) => {
            this.fastResultados.total = res
            this.fastResultados.filtro = res
            this.fastBtnLoading = false
            this.exibeToasty("Resultado cadastrado com sucesso", "success")
            this.alteraFastNavegacao('exibir')      
            
            this.fastResultadoAlunoNovo = {
              id_tipo_resultado_aluno: 0,
              nome_tipo_resultado: "",
              indica_aprovacao: false,
              indica_reprovacao: false,
              libera_preriquisito: false,
              id_plataforma: this.$route.params.id_plataforma,
              deriva_plataforma_filha: false,        
            }
          }).catch((e) => {
              this.exibeToasty("Erro ao criar sala", "error");
              this.fastBtnLoading = false
          });
      }      
    },
    exibeModalEditarFastResultado(resultado) {      
      this.fastBtnLoading = false
      this.fastResultadoAlunoEditar = resultado 
      this.alteraFastNavegacao('editar')    
    },
    async editarFastResultado() {   
      this.fastBtnLoading = true   
      let erros = []        
      if (!this.fastResultadoAlunoEditar.nome_tipo_resultado) erros.push("O nome do tipo de resultado é obrigatório")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastBtnLoading = false
      } else {
          this.promisePostFastApi(this.fastResultadoAlunoEditar, "api/fast_secretaria_resultado_aluno/atualiza").then(res => {
            this.exibeToasty("Salvo com sucesso", "success");  
            this.alteraFastNavegacao('exibir')
            this.fastResultados.total = res
            this.fastResultados.filtro = res
            this.fastBtnLoading = false
          }).catch(e => {
              this.exibeToasty("Erro ao salvar alterações", "error");
              this.fastBtnLoading = false
          });
      }        
    },
    exibeModalExcluirFastResultado(resultado) {
      this.fastBtnLoading = false
      this.fastResultadoAlunoEditar = resultado
      this.showModal("modalExcluirResultado")
    },
    async excluirFastResultado(){
      this.fastBtnLoading = true   
      this.promisePostFastApi(this.fastResultadoAlunoEditar, "api/fast_secretaria_resultado_aluno/exclui").then(e => {
        this.exibeToasty("Resultado excluído com sucesso", "success")       
        this.getfastResultados(this.$route.params.id_plataforma) 
        this.hideModal("modalExcluirResultado")
        this.fastBtnLoading = false
      }).catch(e => {
          this.exibeToasty("Erro ao excluir", "error");
          this.fastBtnLoading = false
      });
    },
  },
};
</script>

<style scope>
</style>
